import '../../facilitate/AssessmentDetails.scss';

import { AssessmentFeature } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AuthContextInterface, FlashType, withAuthContext } from '@amzn/awscat-react-components';
import { ReportTemplate, TemplateSearch } from '@amzn/awscat-report-generation-service-typescript-client';
import { Alert, Box, Button, Container, Header, Icon, Link, SpaceBetween, Tiles } from '@amzn/awsui-components-react';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import { GetEnvironmentConfig } from '../../../../Environments';
import reportGenerationClient from '../../../../api/rgs/RgsClient';
import summaryGenerationClient from '../../../../api/summaryGeneration/SummaryGenerationClient';
import { downloadUrl, openUrlInNewTab } from '../../../../assessments/reports/GenerateReport';
import StratumColorizer from '../../../../assessments/reports/StratumColorizer';
import { generateDocxReport } from '../../../../assessments/reports/report-types/GenerateDocxReport';
import { generateExcelReport } from '../../../../assessments/reports/report-types/GenerateExcelReport';
import { FitScores, generatePptxSummaryReport } from '../../../../assessments/reports/report-types/GeneratePptxReport';
import { convertAssessmentToSummarizationInput } from '../../../../assessments/reports/report-types/GenerateSummary';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../common/AppLabelsContext';
import Constants from '../../../../common/Constants';
import { isApnUser } from '../../../../common/Utils';
import rumClient from '../../../../common/monitoring/RumClient';
import { ScoreScale } from '../../../../common/score/ScoreScale';
import RequestStatusFlashbar, { RequestStatus, defaultRequestStatus } from '../../../common/RequestStatusFlashbar';
import { clearAppHelpPanel, openAppHelpPanel, updateAppHelpPanel } from '../../../common/help-panel/AppHelpPanelSlice';
import { LocalizationContextInterface, withLocalizationContext } from '../../../localization/LocalizationContext';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { AssessmentViewModel } from '../../AssessmentViewModel';
import AssessmentDetailsHeader from '../../facilitate/header/AssessmentDetailsHeader';

const getReportTemplates = async (templateSearch: TemplateSearch, locale: string): Promise<ReportTemplate[]> => {
    const reportTemplates: ReportTemplate[] = await reportGenerationClient.getReportTemplates(templateSearch);
    const localeReportTemplates: ReportTemplate[] = reportTemplates.filter((t) => t.tags.locale === locale);
    if (localeReportTemplates?.length > 0) {
        return localeReportTemplates;
    } else {
        const defaultLocaleReportTemplates: ReportTemplate[] = reportTemplates.filter((t) => t.tags.locale === Constants.DEFAULT_LOCALE);
        if (defaultLocaleReportTemplates?.length) {
            return defaultLocaleReportTemplates;
        }
    }
    return [];
};

const getFitScores = (assessment: AssessmentViewModel): FitScores => {
    const fitScores: FitScores = {
        amsFitScore: 0, // initialize to 0%
    };
    const assessmentFitScores = assessment.assessmentScores;
    if (assessmentFitScores === null) return fitScores;

    for (const assessmentFitScore of assessmentFitScores) {
        const parsedScore = JSON.parse(assessmentFitScore.scoreDetails);
        const score = parsedScore['totalScore'];
        const maxScore = parsedScore['maxScore'];
        if (assessmentFitScore.scoreType === 'AMS_FIT_SCORE') fitScores.amsFitScore = score / maxScore;
    }

    return fitScores;
};

const GenerateReportMenu: FunctionComponent<AppLabelsContextInterface & AuthContextInterface & LocalizationContextInterface> = ({
    appLabels,
    auth,
    locale,
}): JSX.Element => {
    const dispatch = useAppDispatch();

    // Assessment & recommendations
    const currentAssessment = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const currentLivePollingResponses = useAppSelector((state) => state.currentAssessmentState.liveSessionResponses);

    const currentAssessmentState = useAppSelector((state) => state.currentAssessmentState);
    const refIdToRecommendationsMap = useAppSelector((state) => state.recommendationsState.refIdToRecommendationsMap);

    // User permissions
    const authorizedFeatures = useAppSelector((state) => state.assessmentFeaturesState.authorizedFeatures);
    const rsIsEnabled = authorizedFeatures.includes(AssessmentFeature.ADMIN_RS_ENABLED);

    // Assessment info
    const assessmentType = currentAssessment?.template?.type;
    const templateVersion = currentAssessment?.template?.version;
    const templateReportConfig = currentAssessment?.template?.defaults?.report;
    const targetSegmentId = currentAssessment?.targetSegmentId;

    // User info
    const userName = auth.getUserInfo().displayName;
    const organizationName = auth.getUserInfo().organizationName || '';

    // When a user selects a tile, the ID of the report they want to generate is set
    const [selectedPptxTemplateId, setSelectedPptxTemplateId] = React.useState<string>(null);
    const [selectedWordTemplateId, setSelectedWordTemplateId] = React.useState<string>(null);
    const [selectedXlsxTemplateId, setSelectedXlsxTemplateId] = React.useState<string>(null);
    const [selectedSummaryId, setSelectedSummaryId] = React.useState<string>(null);

    // Request statuses for all calls
    const [templateLookupRequestStatus, setTemplateLookupRequestStatus] = useState<RequestStatus>(defaultRequestStatus);
    const [generatePptxRequestStatus, setGeneratePptxRequestStatus] = useState<RequestStatus>(defaultRequestStatus);
    const [generateWordRequestStatus, setGenerateWordRequestStatus] = useState<RequestStatus>(defaultRequestStatus);
    const [generateXlsxRequestStatus, setGenerateXlsxRequestStatus] = useState<RequestStatus>(defaultRequestStatus);
    const [generateSummaryRequestStatus, setGenerateSummaryRequestStatus] = useState<RequestStatus>(defaultRequestStatus);

    // The available reports to generate
    const [pptxTemplates, setPptxTemplates] = useState<ReportTemplate[] | null>(null);
    const [pptxTemplateTiles, setPptxTemplateTiles] = useState(null);
    const [wordTemplates, setWordTemplates] = useState<ReportTemplate[] | null>(null);
    const [wordTemplateTiles, setWordTemplateTiles] = useState(null);
    const [xlsxTemplates, setXlsxTemplates] = useState<ReportTemplate[] | null>(null);
    const [xlsxTemplateTiles, setXlsxTemplateTiles] = useState(null);
    const [summaryTiles, setSummaryTiles] = useState(null);

    const REPORT_TYPE_PPTX = 'pptx';
    const REPORT_TYPE_WORD = 'docx';
    const REPORT_TYPE_XLSX = 'xlsx';

    const scoreScale = useMemo(() => {
        return new ScoreScale(currentAssessment?.template?.defaults?.questionnaireAnswers, appLabels);
    }, [appLabels, currentAssessment?.template?.defaults?.questionnaireAnswers]);

    /**
     * Display the summary generation option, only if we're on a non-prod environment, and the user is
     * designated to test the feature
     */
    const shouldDisplaySummaryOption = useMemo(() => {
        const environment = GetEnvironmentConfig();

        if (!environment.summaryGenerationApiEndpoint.length) {
            return false;
        }

        // Temporary mechanism for showing this option only to a few users
        const featureTesters: string[] = [
            'samehdi',
            'yyuezho',
            'monaror',
            'addicox',
            'artba',
            'vjkan',
            'arushix',
            'akkatdar',
            'toddtjo',
            'gengis',
            'supoa',
            'gulldavi',
            'nilanj',
        ];

        return featureTesters.some((allowedUsername) => auth.getUserInfo().userId.includes(allowedUsername));
    }, [auth]);

    const loadReportTemplates = useCallback(async () => {
        if (
            templateLookupRequestStatus.loading ||
            templateLookupRequestStatus.messageType === FlashType.error ||
            pptxTemplates ||
            wordTemplates ||
            xlsxTemplates
        ) {
            // template already loaded, no need to load again
            return;
        }
        try {
            setTemplateLookupRequestStatus({
                ...templateLookupRequestStatus,
                loading: true,
                messageHeader: appLabels.assessment.results.generate_report.report_template_lookup,
            });

            const templateSearchFilter = {
                appID: Constants.A2T_APP_ID,
                tagFilters: {
                    assessmentType: assessmentType,
                    assessmentTemplateVersions: [templateVersion],
                    targetSegmentIds: targetSegmentId ? [targetSegmentId] : undefined,
                },
            } as TemplateSearch;
            const results = await getReportTemplates(templateSearchFilter, locale);
            // Pptx templates
            if (!templateReportConfig?.ppt?.disabled) {
                const availablePptxTemplates: ReportTemplate[] = results.filter((r) => r.tags.reportType === REPORT_TYPE_PPTX);
                setPptxTemplates(availablePptxTemplates);
                setPptxTemplateTiles(
                    availablePptxTemplates
                        ?.map((t) => ({
                            label: tileLabelElement(t.title, t.description),
                            value: t.templateID,
                        }))
                        .sort()
                );
            }
            // Word templates
            if (!templateReportConfig?.word?.disabled) {
                const availableWordTemplates: ReportTemplate[] = results.filter((r) => r.tags.reportType === REPORT_TYPE_WORD);
                setWordTemplates(availableWordTemplates);
                setWordTemplateTiles(
                    availableWordTemplates
                        ?.map((t) => ({
                            label: tileLabelElement(t.title, t.description),
                            value: t.templateID,
                        }))
                        .sort()
                );
            }
            // Excel templates
            if (!templateReportConfig?.excel?.disabled) {
                const availableXlsxTemplates: ReportTemplate[] = results.filter((r) => r.tags.reportType === REPORT_TYPE_XLSX);
                setXlsxTemplates(availableXlsxTemplates);
                setXlsxTemplateTiles(
                    availableXlsxTemplates
                        ?.map((t) => ({
                            label: tileLabelElement(t.title, t.description),
                            value: t.templateID,
                        }))
                        .sort()
                );
            }
            // Set summary tile once other report types are loaded
            if (shouldDisplaySummaryOption) {
                setSummaryTiles([
                    {
                        label: tileLabelElement('Assessment summary', 'Includes questions, answers, comments, and recommended actions'),
                        value: 'assessment-summary',
                    },
                ]);
            }
            setTemplateLookupRequestStatus({
                ...templateLookupRequestStatus,
                loading: false,
                messageHeader: appLabels.assessment.results.generate_report.report_template_lookup,
            });
        } catch (error: any) {
            rumClient.recordError(error);
            setTemplateLookupRequestStatus({
                ...templateLookupRequestStatus,
                loading: false,
                messageType: FlashType.error,
                messageHeader: appLabels.assessment.results.generate_report.error_report_template_lookup,
                messageContent: error.message ?? '',
            });
        }
    }, [
        pptxTemplates,
        wordTemplates,
        xlsxTemplates,
        templateLookupRequestStatus,
        setTemplateLookupRequestStatus,
        appLabels.assessment.results.generate_report.report_template_lookup,
        appLabels.assessment.results.generate_report.error_report_template_lookup,
        assessmentType,
        targetSegmentId,
        templateVersion,
        locale,
        templateReportConfig?.excel?.disabled,
        templateReportConfig?.word?.disabled,
        templateReportConfig?.ppt?.disabled,
        shouldDisplaySummaryOption,
    ]);

    const generateReport = useCallback(async (): Promise<any> => {
        const selectedPptxTemplate: ReportTemplate = pptxTemplates?.filter((t) => t.templateID === selectedPptxTemplateId)?.at(0);
        const selectedWordTemplate: ReportTemplate = wordTemplates?.filter((t) => t.templateID === selectedWordTemplateId)?.at(0);
        const selectedXlsxTemplate: ReportTemplate = xlsxTemplates?.filter((t) => t.templateID === selectedXlsxTemplateId)?.at(0);
        const isPartner = isApnUser(auth?.getUserInfo().userId);
        if (currentAssessment && scoreScale) {
            if (selectedPptxTemplate) {
                setGeneratePptxRequestStatus({
                    loading: true,
                    messageType: FlashType.info,
                    messageHeader: appLabels.assessment.results.generate_report.report_download_progress_text,
                    messageContent: null,
                });
                try {
                    // Get fit scores, generate the PowerPoint report, and download it
                    const fitScores = getFitScores(currentAssessment);
                    const colorizer = new StratumColorizer(scoreScale);
                    const url = await generatePptxSummaryReport(
                        userName,
                        organizationName,
                        fitScores,
                        appLabels,
                        currentAssessment,
                        colorizer,
                        rsIsEnabled,
                        refIdToRecommendationsMap,
                        locale,
                        selectedPptxTemplate.templateID,
                        isPartner
                    );
                    downloadUrl(url);
                    setGeneratePptxRequestStatus({
                        messageType: FlashType.success,
                        loading: false,
                        messageHeader: appLabels.assessment.results.generate_report.report_download_complete_text,
                        messageContent: null,
                    });
                } catch (error: any) {
                    rumClient.recordError(error);
                    setGeneratePptxRequestStatus({
                        messageType: FlashType.error,
                        loading: false,
                        messageHeader: appLabels.assessment.results.generate_report.error_report_download,
                        messageContent: error.message ?? '',
                    });
                }
            }
            if (selectedWordTemplate) {
                setGenerateWordRequestStatus({
                    messageType: FlashType.info,
                    loading: true,
                    messageHeader: '',
                    messageContent: appLabels.assessment.results.generate_report.report_download_progress_text,
                });
                try {
                    const colorizer = new StratumColorizer(scoreScale);
                    const url = await generateDocxReport(
                        appLabels,
                        currentAssessment,
                        colorizer,
                        selectedWordTemplate.templateID,
                        locale,
                        isPartner,
                        rsIsEnabled,
                        refIdToRecommendationsMap
                    );
                    downloadUrl(url);
                    setGenerateWordRequestStatus({
                        messageType: FlashType.success,
                        loading: false,
                        messageHeader: appLabels.assessment.results.generate_report.report_download_complete_text,
                        messageContent: null,
                    });
                } catch (error: any) {
                    rumClient.recordError(error);
                    setGenerateWordRequestStatus({
                        messageType: FlashType.error,
                        loading: false,
                        messageHeader: appLabels.assessment.results.generate_report.error_report_download,
                        messageContent: error.message ?? '',
                    });
                }
            }
            if (selectedXlsxTemplate) {
                setGenerateXlsxRequestStatus({
                    messageType: FlashType.info,
                    loading: true,
                    messageHeader: appLabels.assessment.results.generate_report.report_download_progress_text,
                    messageContent: null,
                });
                try {
                    const url = await generateExcelReport(
                        appLabels,
                        currentAssessment,
                        currentLivePollingResponses,
                        selectedXlsxTemplate.templateID,
                        rsIsEnabled,
                        refIdToRecommendationsMap
                    );
                    downloadUrl(url);
                    setGenerateXlsxRequestStatus({
                        messageType: FlashType.success,
                        loading: false,
                        messageHeader: appLabels.assessment.results.generate_report.report_download_complete_text,
                        messageContent: null,
                    });
                } catch (error: any) {
                    rumClient.recordError(error);
                    setGenerateXlsxRequestStatus({
                        messageType: FlashType.error,
                        loading: false,
                        messageHeader: appLabels.assessment.results.generate_report.error_report_download,
                        messageContent: error.message ?? '',
                    });
                }
            }
            if (selectedSummaryId) {
                setGenerateSummaryRequestStatus({
                    messageType: FlashType.info,
                    loading: true,
                    messageHeader: appLabels.assessment.results.generate_report.report_download_progress_text,
                    messageContent: null,
                });
                try {
                    const summarizationInput = convertAssessmentToSummarizationInput(currentAssessmentState, appLabels);
                    const summary: string = (await summaryGenerationClient.summarizeAssessment(summarizationInput)).summary;

                    setGenerateSummaryRequestStatus({
                        messageType: FlashType.success,
                        loading: false,
                        messageHeader: appLabels.assessment.results.generate_report.report_download_complete_text,
                        messageContent: null,
                    });

                    // Create a text file and display it in new window
                    const textFile = new Blob([summary], { type: 'text/plain' });
                    const url = URL.createObjectURL(textFile);
                    openUrlInNewTab(url);
                } catch (error: any) {
                    rumClient.recordError(error);
                    console.error(error);
                    setGenerateSummaryRequestStatus({
                        messageType: FlashType.error,
                        loading: false,
                        messageHeader: appLabels.assessment.results.generate_report.error_report_download,
                        messageContent: error.message ?? '',
                    });
                }
            }
        } else {
            setGeneratePptxRequestStatus({
                messageType: FlashType.error,
                loading: false,
                messageHeader: appLabels.assessment.results.generate_report.error_assessment_scorecard,
                messageContent: null,
            });
            throw Error('Assessment or scorescale not provided');
        }
    }, [
        pptxTemplates,
        wordTemplates,
        xlsxTemplates,
        currentAssessment,
        currentAssessmentState,
        currentLivePollingResponses,
        scoreScale,
        selectedPptxTemplateId,
        selectedWordTemplateId,
        selectedXlsxTemplateId,
        selectedSummaryId,
        userName,
        organizationName,
        appLabels,
        locale,
        rsIsEnabled,
        refIdToRecommendationsMap,
        auth,
    ]);

    const tileLabelElement: (label: string, description?: string) => JSX.Element = (label: string, description?: string): JSX.Element => {
        return (
            <SpaceBetween size='xxs'>
                <>
                    {label}
                    {description ? <small className='grey-text'>{description}</small> : null}
                </>
            </SpaceBetween>
        );
    };

    useEffect(() => {
        const cleanup = () => {
            // Component unmounted, restore help panel to default
            dispatch(clearAppHelpPanel());
        };
        dispatch(
            updateAppHelpPanel({
                header: appLabels.assessment.results.generate_report.generate_report,
                content: appLabels.assessment.results.generate_report.info_panel_text,
            })
        );
        loadReportTemplates();
        return cleanup;
    }, [
        appLabels.assessment.results.generate_report.generate_report,
        appLabels.assessment.results.generate_report.info_panel_text,
        dispatch,
        loadReportTemplates,
    ]);

    const isReportTemplateAvailable = useCallback(() => {
        if (pptxTemplates?.length || wordTemplates?.length || xlsxTemplates?.length || summaryTiles?.length) {
            return true;
        }
        return false;
    }, [pptxTemplates, wordTemplates, xlsxTemplates, summaryTiles]);

    const reportTemplateNotAvailableAlert = useCallback(() => {
        // If not looking up template or no template available
        if (!(templateLookupRequestStatus.loading || isReportTemplateAvailable())) {
            return (
                <Alert visible={true} dismissAriaLabel={appLabels.user_actions.close_alert} type='info'>
                    {appLabels.assessment.results.generate_report.report_template_not_available}
                </Alert>
            );
        }
        return null;
    }, [
        templateLookupRequestStatus.loading,
        isReportTemplateAvailable,
        appLabels.user_actions.close_alert,
        appLabels.assessment.results.generate_report.report_template_not_available,
    ]);

    const reportDownloadButton = useCallback(() => {
        if (isReportTemplateAvailable()) {
            return (
                <Box float='right' padding={{ bottom: 'm' }}>
                    <Button
                        disabled={!(selectedPptxTemplateId || selectedWordTemplateId || selectedXlsxTemplateId || selectedSummaryId)}
                        onClick={generateReport}
                        loading={
                            generatePptxRequestStatus.loading ||
                            generateWordRequestStatus.loading ||
                            generateXlsxRequestStatus.loading ||
                            generateSummaryRequestStatus.loading
                        }
                        variant='primary'
                        data-testid='generate-report-button'
                    >
                        {appLabels.assessment.results.generate_report.download}
                    </Button>
                </Box>
            );
        }
        return null;
    }, [
        isReportTemplateAvailable,
        selectedPptxTemplateId,
        selectedWordTemplateId,
        selectedXlsxTemplateId,
        selectedSummaryId,
        generateReport,
        generatePptxRequestStatus.loading,
        generateWordRequestStatus.loading,
        generateXlsxRequestStatus.loading,
        generateSummaryRequestStatus.loading,
        appLabels.assessment.results.generate_report.download,
    ]);

    return (
        <Box padding={{ bottom: 'xxl' }} className='awscat-assessment-results assessment-form' variant='div'>
            <AssessmentDetailsHeader showLiveSession={false} readOnly={true} showSnapshots={true} />

            <Box variant='h2' margin={{ top: 'm', bottom: 'm' }}>
                {appLabels.assessment.results.generate_report.generate_report}
                <Box margin={{ left: 'xs' }} display='inline-block'>
                    <Link variant='info' id='assessment-generate-report-info-link' onFollow={() => dispatch(openAppHelpPanel())}>
                        {appLabels.common.info}
                    </Link>
                </Box>
            </Box>
            <RequestStatusFlashbar requestStatus={templateLookupRequestStatus} setRequestStatus={setTemplateLookupRequestStatus} />
            {reportTemplateNotAvailableAlert()}
            <Box padding={{ bottom: 'm' }}>
                <SpaceBetween size='m'>
                    {pptxTemplates?.length > 0 ? (
                        <Container
                            id='pptx-reports'
                            header={
                                <Header variant='h2' description={appLabels.assessment.results.generate_report.powerpoint_slides}>
                                    {appLabels.assessment.results.generate_report.results_presentation}
                                </Header>
                            }
                        >
                            <SpaceBetween size='xxs'>
                                <span>
                                    <Tiles
                                        onChange={({ detail }) => {
                                            setSelectedPptxTemplateId(detail.value);
                                            // Unselect all other options too
                                            setSelectedWordTemplateId(null);
                                            setSelectedXlsxTemplateId(null);
                                            setSelectedSummaryId(null);
                                        }}
                                        value={selectedPptxTemplateId}
                                        items={pptxTemplateTiles}
                                        data-testid='pptx-tiles'
                                    />
                                </span>
                                <RequestStatusFlashbar requestStatus={generatePptxRequestStatus} setRequestStatus={setGeneratePptxRequestStatus} />
                            </SpaceBetween>
                        </Container>
                    ) : null}
                    {wordTemplates?.length > 0 ? (
                        <Container
                            id='word-reports'
                            header={
                                <Header variant='h2' description={appLabels.assessment.results.generate_report.word_document}>
                                    {appLabels.assessment.results.generate_report.assessment_report}
                                </Header>
                            }
                        >
                            <SpaceBetween size='xxs'>
                                <span>
                                    <Tiles
                                        onChange={({ detail }) => {
                                            setSelectedWordTemplateId(detail.value);
                                            setSelectedPptxTemplateId(null);
                                            setSelectedXlsxTemplateId(null);
                                            setSelectedSummaryId(null);
                                        }}
                                        value={selectedWordTemplateId}
                                        items={wordTemplateTiles}
                                        data-testid='docx-tiles'
                                    />
                                </span>
                                <RequestStatusFlashbar requestStatus={generateWordRequestStatus} setRequestStatus={setGenerateWordRequestStatus} />
                            </SpaceBetween>
                        </Container>
                    ) : null}
                    {xlsxTemplates?.length > 0 ? (
                        <Container
                            id='xlsx-reports'
                            header={
                                <Header variant='h2' description={appLabels.assessment.results.generate_report.excel_spreadsheet}>
                                    {appLabels.assessment.results.generate_report.recommended_action_backlog}
                                </Header>
                            }
                        >
                            <SpaceBetween size='xxs'>
                                <span>
                                    <Tiles
                                        onChange={({ detail }) => {
                                            setSelectedXlsxTemplateId(detail.value);
                                            setSelectedWordTemplateId(null);
                                            setSelectedPptxTemplateId(null);
                                            setSelectedSummaryId(null);
                                        }}
                                        value={selectedXlsxTemplateId}
                                        items={xlsxTemplateTiles}
                                        data-testid='xlsx-tiles'
                                    />
                                </span>
                                <RequestStatusFlashbar requestStatus={generateXlsxRequestStatus} setRequestStatus={setGenerateXlsxRequestStatus} />
                            </SpaceBetween>
                        </Container>
                    ) : null}
                    {summaryTiles?.length ? (
                        <Container
                            header={
                                <Header variant='h2' description={'Brief summary of assessment results and next steps'}>
                                    {'Assessment summary '}
                                    {<Icon name='gen-ai' />}
                                </Header>
                            }
                        >
                            <SpaceBetween size='xxs'>
                                <span>
                                    <Tiles
                                        onChange={({ detail }) => {
                                            setSelectedSummaryId(detail.value);
                                            setSelectedXlsxTemplateId(null);
                                            setSelectedWordTemplateId(null);
                                            setSelectedPptxTemplateId(null);
                                        }}
                                        value={selectedSummaryId}
                                        items={summaryTiles}
                                    />
                                </span>
                                <RequestStatusFlashbar
                                    requestStatus={generateSummaryRequestStatus}
                                    setRequestStatus={setGenerateSummaryRequestStatus}
                                />
                            </SpaceBetween>
                        </Container>
                    ) : null}
                </SpaceBetween>
            </Box>
            {reportDownloadButton()}
        </Box>
    );
};

export default withLocalizationContext(withAuthContext(withAppLabelsContext(GenerateReportMenu)));
