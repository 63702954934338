import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { GetEnvironmentConfig } from '../../Environments';
import { getJwtToken } from '../../common/auth/Authentication';

// TODO: consume these types from the SGS client package, once created
export interface SummarizationComment {
    commentText: string;
    writtenBy: string;
    role: string;
}

export interface SummarizationQuestion {
    questionText: string;
    comments: SummarizationComment[];
    response: string;
}

export interface SummarizationAction {
    action: string;
    priority: string;
    effort: string;
}

export interface SummarizationAssessmentMetadata {
    assessmentName: string;
    assessmentTypeName: string;
    assessmentTypeDescription: string;
    assessmentTypeAbbreviation: string;
    customerName: string;
}

export interface SummarizationInput {
    questions: SummarizationQuestion[];
    recommendedActions: SummarizationAction[];
    assessmentMetadata: SummarizationAssessmentMetadata;
}

export interface SummarizationOutput {
    summary: string;
}

const envConfig = GetEnvironmentConfig();

// TODO: make separate SGS client package. Will probably use Smithy
export class SummaryGenerationClient {
    private service: AxiosInstance;
    private readonly serviceUrl: string;

    constructor() {
        this.serviceUrl = envConfig.summaryGenerationApiEndpoint;
        this.service = axios.create({
            baseURL: this.serviceUrl,
        });

        this.service.interceptors.request.use(async (config: AxiosRequestConfig) => {
            const authToken = await getJwtToken();
            if (config && config.headers) {
                config.headers.Authorization = `Bearer ${authToken}`;
            }

            return config;
        }, undefined);
    }

    public summarizeAssessment = async (summaryInput: SummarizationInput): Promise<SummarizationOutput> => {
        const result = await this.service.post('summarize', summaryInput);
        return result.data;
    };
}

const summaryGenerationClient = new SummaryGenerationClient();
export default summaryGenerationClient;
