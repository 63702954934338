import { AwsAssessmentRecommendationServiceClient } from '@amzn/aws-assessment-recommendation-service-client';

import { GetEnvironmentConfig } from '../../Environments';
import { getJwtToken } from '../../common/auth/Authentication';

const envConfig = GetEnvironmentConfig();

let rsClient = null;
/**
 * Function to get Recommendation Service Client
 * @returns {AwsAssessmentRecommendationServiceClient} client
 */
export function getRecommendationServiceClient(): AwsAssessmentRecommendationServiceClient {
    if (rsClient) {
        return rsClient;
    }
    const client = new AwsAssessmentRecommendationServiceClient({ endpoint: envConfig.rsApiEndpoint });
    client.middlewareStack?.add(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (next: any) => async (args: any) => {
            args.request.headers['Authorization'] = await getJwtToken();
            const result = await next(args);
            return result;
        },
        {
            step: 'build',
        }
    );

    rsClient = client;
    return rsClient;
}
